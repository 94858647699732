import CarouselList from "../../components/MyCarousel/CarouselList";
import React from "react";
import "./websites.css";
import { MDBAnimation } from "mdbreact";
import websitesData from "./websites-data";

const Websites = (props) => {
  return (
    <div className="container-fluid">
      <MDBAnimation
        type="fadeIn"
        delay="1s"
        className="text-center"
        data-mdb-animation-start="onHover"
      >
        <div className="container" id="websites">
          <div className="col-sm-12 text-center">
            {!props.isHome ? (
              <h1 className="sectionHeadline">Projects</h1>
            ) : (
              <h2 className="display-h1 sectionHeadline">Projects</h2>
            )}
            <br />
            <CarouselList myObject={websitesData} />
            {/* <MyCarousel
                        myObject={websites}
                    /> */}
          </div>
        </div>
        <MDBAnimation
          type="fadeIn"
          delay=".8s"
          className="text-center arrowDown"
          id="arrowDownWebsites"
        >
          <a href="#experience">
            <i
              className="arrow bounce text-center fas fa-angle-down"
              onClick={() => props.setDisplay("experience")}
            ></i>
          </a>
        </MDBAnimation>
      </MDBAnimation>
    </div>
  );
};
export default Websites;
