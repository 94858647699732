import React from "react";
import "./experience.css";
import { MDBCard, MDBAnimation, MDBCol } from "mdbreact";
const Experience = (props) => {
  return (
    <div className="container-fluid">
      <MDBAnimation type="fadeIn" delay="1s" className="text-center">
        <div className="container" id="experience">
          <div className="col-sm-12 text-center">
            {/* <MDBRow>
                        <MDBCol>

                            <div className='cardOfMe'> */}
            {!props.isHome ? (
              <h1>My Experience</h1>
            ) : (
              <h2 className="display-h1 sectionHeadline">My Experience</h2>
            )}
            <br />
            <br />
            <i className="fas fa-laptop-code fa-5x col-sm-12"></i>
            <br />
            <br /> <br />
            <br />
            <div className="showcase-content col-sm-12 ">
              <ul>
                <div className="itemOfExperience">
                  <li>
                    <strong>
                      2020-2023 - Full Stack Developer at{" "}
                      <a
                        className="font-italic experienceLinks"
                        title="https://hrplus.co.il/"
                        href="https://hrplus.co.il/"
                        target="_blank"
                      >
                        HRplus
                      </a>
                    </strong>
                  </li>
                  <div>
                    <ul className="font-italic experienceTechnologies">
                      <li>
                        Developed and maintained client and server sides of
                        web-based e-learning SaaS products with: Node.js, React,
                        TypeScript, Nestjs, .NET, SQL Server DB and MongoDB.
                      </li>
                      <li>
                        Design and develop on-demand mobile application from
                        scratch with React + React Native and Expo
                      </li>
                      <li>
                        Maintained company servers on AWS EC2 and maintained
                        Digital Ocean Spaces and S3 for storage and content
                        delivery. Configured and optimized website security
                        using Cloudflare's CDN.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
            </div>
          </div>

          <MDBAnimation
            type="fadeIn"
            delay=".8s"
            className="text-center arrowDown"
            id="arrowDownB"
          >
            <a href="#landing">
              <i className="arrow bounce text-center fas fa-angle-up"></i>
            </a>
          </MDBAnimation>
          {/* </MDBCol>
                    </MDBRow> */}

          {/* </div> */}
        </div>
      </MDBAnimation>
    </div>
  );
};
export default Experience;
