import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <p>
        Created by
        <a href="https://github.com/nir11" target="_blank">
          {" "}
          @Nir Almog
        </a>{" "}
        2023
      </p>
    </div>
  );
};
export default Footer;
