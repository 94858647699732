const websitesData = [
  {
    id: 1,
    name: "File Type Checker",
    src: "../img/websites/file-type-checker.png",
    url: "https://www.npmjs.com/package/file-type-checker",
    form: null,
    github: "https://github.com/nir11/file-type-checker",
    technologies: ["Node.js", "TypeScript", "Jest"],
    year: "2023",
    frame: null,
    description: `NPM package for detection and validation of file types by their signatures (✨magic numbers✨)`,
    background: "transparent",
    login: null,
  },
  {
    id: 1,
    name: "Event Driven Microservices: Cat Adoption Center",
    src: "../img/websites/cat-adoption-center-architecture.png",
    url: null,
    form: null,
    github:
      "https://github.com/nir11/node-rabbitmq-docker-event-driven-microservices",
    technologies: ["Docker", "Node.js", "RabbitMQ", "MongoDB", "TypeScript"],
    year: "2023",
    frame: null,
    description: `Event Driven Microservices with Node.js, RabbitMQ, Docker and MongoDB`,
    background: "transparent",
    login: null,
  },
  {
    id: 2,
    name: "Meshek Almog",
    src: "../img/websites/almog.jpg",
    url: "https://meshekalmog.co.il/",
    form: "https://meshekalmog.co.il/groups/14/6111177771a96b07d8def610",
    github: null,
    technologies: [
      "React(Redux, Hooks, Axios)",
      "MongoDB",
      "Node.js",
      "Express.js",
      "mdbReact",
    ],
    year: "2020-2022",
    frame: null,
    description: `A website for my family business. Includes information, contact details, a gallery, and a convenient vegetable ordering form.`,
    background: "transparent",
    login: null,
  },
  {
    id: 3,
    name: "EasyTest",
    src: "../img/websites/driving.png",
    url: "https://easytest.onrender.com/",
    form: null,
    github: "https://github.com/nir11/EasyTest",
    technologies: [
      "React (Redux, Hooks, Axios)",
      "Bootstrap",
      "Node.js",
      `Express`,
      "MongoDB, Geolocation API, Google Maps",
    ],
    year: "2022-2023",
    frame: null,
    description: `Car test registration and appointment booking, with personalized recommendations based on distance and remaining days.`,
    background: "#212529",
    login: null,
  },
  {
    id: 4,
    name: "API Builder",
    src: "../img/websites/api-builder.gif",
    url: null,
    form: null,
    github: "https://github.com/nir11/api-builder",
    technologies: ["Node.js", "TypeScript"],
    year: "2023",
    frame: null,
    description: `Simplifying API request construction and execution by using the Builder Design Pattern.`,
    background: "#212529",
    login: null,
  },
  {
    id: 5,
    name: "Friend List Management",
    src: "../img/websites/friend-list-manageent.png",
    url: null,
    form: null,
    github: "https://github.com/nir11/friend-list-management",
    technologies: [
      "React",
      "TypeScript",
      "Bootstrap",
      "https://randomuser.me API",
    ],
    year: "2023",
    frame: null,
    description:
      "Friend list management system using https://randomuser.me API.",
    background: "#212529",
    login: null,
  },
  {
    id: 6,
    name: "Car Management System",
    src: "../img/websites/car-management-system.png",
    url: null,
    form: null,
    github: "https://github.com/nir11/car-management-system",
    technologies: [
      ".NET Core 6.0",
      "SQL Server",
      "React",
      "TypeScript",
      "Bootstrap",
    ],
    year: "2023",
    frame: null,
    description:
      "Car management system for registering, updating, deleting, and activating various cars.",
    background: "#212529",
    login: null,
  },
  {
    id: 7,
    name: "Hopetimize App",
    src: "../img/websites/hopetimize.png",
    url: "https://play.google.com/store/apps/details?id=com.hrplus.hopetimize",
    form: null,
    github: null,
    technologies: [
      "Node.js",
      "NestJS",
      "TypeScript",
      "MongoDB",
      "React",
      "React Native",
      "Expo",
    ],
    year: "2023",
    frame: null,
    description:
      "The Hopetimize app empowers individuals facing illness or the later stages of life to find hope, set meaningful goals, and live life to the fullest with uplifting messages, support, and guidance.",
    background: "#212529",
    login: null,
  },
  {
    id: 8,
    name: "Database Simulator",
    src: "../img/websites/database-simulator.png",
    url: null,
    form: null,
    github: "https://github.com/nir11/DatabaseSimulator",
    technologies: ["C#", "Windows Forms", "Microsoft SQL Server"],
    year: "2019",
    frame: null,
    description:
      "A graphical user interface based on SQL Server databases. Allows the user to make queries and get results in a convenient way",
  },
  {
    id: 9,
    name: "ProBit App",
    src: "../img/websites/probit.png",
    url: "https://play.google.com/store/apps/details?id=com.probit",
    form: null,
    github: null,
    technologies: ["Android Studio", "Java", "Android's XML", "SQLite"],
    year: "2019",
    frame: null,
    description:
      "Native Android app with programming quizzes, featuring multiple choice questions organized by subjects and difficulty levels.",
    background: "#212529",
    login: null,
  },
];
export default websitesData;
