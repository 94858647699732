const skillsData = [
  {
    id: 1,
    name: "Node.js",
    src: "../img/logos/node.png",
  },
  {
    id: 2,
    name: "React",
    src: "../img/logos/react.png",
  },
  {
    id: 3,
    name: "TypeScript",
    src: "../img/logos/typescript.png",
  },
  {
    id: 4,
    name: "NestJS",
    src: "../img/logos/nestjs.svg",
  },
  {
    id: 5,
    name: "JavaScript ES6",
    src: "../img/logos/js.png",
  },
  {
    id: 22,
    name: "Docker",
    src: "../img/logos/docker.svg",
  },
  {
    id: 23,
    name: "RabbitMQ",
    src: "../img/logos/rabbitmq.svg",
  },
  {
    id: 6,
    name: "Express",
    src: "../img/logos/express.png",
  },
  {
    id: 17,
    name: "RxJS",
    src: "../img/logos/rxjs.png",
  },
  {
    id: 7,
    name: "Redux",
    src: "../img/logos/redux.png",
  },
  {
    id: 8,
    name: "React Hooks",
    src: "../img/logos/hooks.png",
  },
  {
    id: 1,
    name: "HTML 5",
    src: "../img/logos/html.png",
  },
  {
    id: 9,
    name: "CSS 3",
    src: "../img/logos/css.png",
  },
  {
    id: 16,
    name: "AWS",
    src: "../img/logos/aws.svg",
  },
  {
    id: 18,
    name: "Socket.IO",
    src: "../img/logos/socket.io.png",
  },
  {
    id: 10,
    name: ".NET Core 6.0",
    src: "../img/logos/donet-core.png",
  },
  {
    id: 11,
    name: "Jest",
    src: "../img/logos/jest.png",
  },
  {
    id: 12,
    name: "Git",
    src: "../img/logos/git.png",
  },
  {
    id: 13,
    name: "MongoDB",
    src: "../img/logos/mongodb.png",
  },
  {
    id: 14,
    name: "SQL Server",
    src: "../img/logos/sql.png",
  },
  {
    id: 15,
    name: "npm",
    src: "../img/logos/npm.png",
  },
  // {
  //   id: 17,
  //   name: "Solidity",
  //   src: "../img/logos/solidity.png",
  // },
  // {
  //   id: 18,
  //   name: "Hardhat",
  //   src: "../img/logos/hardhat.svg",
  // },
  // {
  //   id: 19,
  //   name: "Ethers.js",
  //   src: "../img/logos/ethersjs.png",
  // },
  // {
  //   id: 20,
  //   name: "Remix",
  //   src: "../img/logos/remix.png",
  // },
  // {
  //   id: 21,
  //   name: "MetaMask",
  //   src: "../img/logos/metamask.png",
  // },
];
export default skillsData;
