import React from "react";
import skillsData from "./skills-data";
import "./skills.css";
import Card from "../../components/Card/Card";
import { MDBRow, MDBAnimation } from "mdbreact";
import "react-tabs/style/react-tabs.css";

const Skiils = (props) => {
  return (
    <div className="container-fluid">
      <MDBAnimation
        type="fadeIn"
        delay="1s"
        className="text-center"
        data-mdb-animation-start="onHover"
      >
        <div className="container" id="skills">
          <div className="col-sm-12 text-center">
            {!props.isHome ? (
              <h1 className="text-center">My Skills</h1>
            ) : (
              <h2 className="display-h1 text-center sectionHeadline">Skills</h2>
            )}
            <br />
            {/* <hr className='skillsHr' />My Portfolio */}
            <div className="showcase-content">
              <MDBRow className="col-sm-12 skillItem animated fadeIn">
                {/* <h3 className="text-center">Web development</h3> */}
                {skillsData.map((technology) => {
                  return (
                    <Card
                      key={technology.id}
                      col="col-sm-4 col-md-3 col-lg-2"
                      name={technology.name}
                      src={technology.src}
                    />
                  );
                })}
              </MDBRow>
            </div>
          </div>
        </div>
        <MDBAnimation
          type="fadeIn"
          delay=".8s"
          className="text-center arrowDown"
          id="arrowDownc"
        >
          <a href="#websites">
            <i
              className="arrow bounce text-center fas fa-angle-down"
              onClick={() => props.setDisplay("websites")}
            ></i>
          </a>
        </MDBAnimation>
      </MDBAnimation>
    </div>
  );
};
export default Skiils;
